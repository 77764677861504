.wrapper{
    width: 100vw;
    display: grid;
    justify-content: center;
    margin-top: 30px;
}
.container{
    width: 100vw;
    max-width: 1120px;
    display: grid;
    gap: 30px;
    grid-auto-flow: column;
    grid-template-columns: auto 1fr;
}
.searchBar{
    width: 280px;
    background-color: rgb(244,190,65);
    border-radius: 5px;
}
.searchBarTitle{
    font-size: 1.2rem;
    font-weight: 500;
}
.searchList{
    display: grid;
    gap: 20px;
}
.searchForm{
    display: grid;
    grid-auto-flow: row;
    padding: 20px;
}
.searchForm label{
    font-size: 0.8rem;
}
.searchForm input{
    border: none;
    height: 27px;
    border-radius: 2px;
    padding: 0 5px;
}
.searchForm button{
    border: none;
    background-color: rgb(48,111,188);
    color: white;
    padding: 12px;
    margin-top: 30px;
    border-radius: 2px;
    cursor: pointer;
}
.optionsSpan{
    margin-top: 20px;
    font-weight: 600;
}