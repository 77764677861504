*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    text-decoration: none;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/*the Meyer reset*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}
body {
    line-height: 1;
}
*{
    text-decoration: none;
}
ol, ul {
    list-style: none;
}
blockquote, q {
    quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}

.navbar_navbar__3u7DR{
    height: 70px;
    background-color: rgb(18,52,123);
    display: grid;
    justify-content: center;
    padding: 20px 0;
}
.navbar_navContainer__QWWlE{
    width: 100vw;
    max-width: 1120px;
    background-color: rgb(18,52,123);
    color: white;
    display: grid;
    grid-auto-flow: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
    /*margin: 20px 0;*/
}
.navbar_logo__209H3{
    font-weight: 900;
    font-size: 1.5rem;
    color: white;
}
.navbar_navItems__JWoLp{
    display: grid;
    grid-auto-flow: column;
    grid-gap: 30px;
    gap: 30px;
}
.navbar_navButton__2dD_C{
    padding: 8px 12px;
    border: none;
    background-color: white;
    color: rgb(18,52,123);
    font-weight: 600;
    cursor: pointer;
}
.navbar_navButtonListProperty__2hQ9H{
    padding: 8px 12px;
    border: 1px solid;
    color: white;
    background-color: rgb(18,52,123);
    font-weight: 600;
    cursor: pointer;
}
.header_header__9QXx0{
    background-color: rgb(18,52,123);
    display: grid;
    justify-content: center;
    align-items: center;
    padding: 20px 0 60px 0;
}
.header_hotelHeader__1at5n{
    background-color: rgb(18,52,123);
    display: grid;
    justify-content: center;
    align-items: center;
    padding: 20px 0 0 0;
}
.header_headerContainer__369d2{
    width: 100vw;
    max-width: 1159px;
    background-color: rgb(18,52,123);
    color: white;
    padding: 0 30px;
}
.header_headerList__3InQH{
    display: grid;
    grid-gap: 5vw;
    gap: 5vw;
    grid-auto-flow: column;
    justify-content: start;
}
.header_headerListItem__2v3w9{
    cursor: pointer;
    padding: 10px 20px;
    border: solid rgb(18,52,123) 1px;
    border-radius: 8px;
    margin-bottom: 30px;
    display: grid;
    grid-auto-flow: column;
    grid-gap: 8px;
    gap: 8px;
    align-items: center;
}
.header_headerListItem__2v3w9.header_active__2Pl61, .header_headerListItem__2v3w9:hover{
    border: solid white 1px;
}
.header_headerH1__34f0L{
    font-weight: 900;
    font-size: 3rem;
    margin: 20px 0;
}
.header_headerDescription__2ozAs{
    /*font-weight: 320;*/
    font-size: 1.5rem;
}
.searchBar_searchBar__3rvOj{
    width: 100vw;
    display: grid;
    justify-content: center;
    font-family: Helvetica,sans-serif;
}
.searchBar_searchBarContainer__21HI0{
    height: 50px;
    width: 100vw;
    max-width: 1120px;
    background-color: rgb(244,190,66);
    display: grid;
    padding: 0 2px;
    align-items: center;
    -webkit-transform: translateY(-25px);
            transform: translateY(-25px);
    border-radius: 3px;
    z-index: 1;
}
.searchBar_searchBarList__1RlgX{
    display: grid;
    grid-gap: 2px;
    gap: 2px;
    grid-auto-flow: column;
    grid-template-columns: 3fr 1fr 3fr 1.5fr;
}
.searchBar_searchBarListItem__22q_H{
    background-color: white;
    border-radius: 3px;
    height: 45px;
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    grid-template-columns: auto 1fr;
    position: relative;
    z-index: 1;
}
.searchBar_searchBarListItem__22q_H.searchBar_person__3g9S0{
    cursor: pointer;
    min-width: 215px;
}
.searchBar_searchBarListItemIcon__2OI4a{
    padding: 10px;
    color: darkgrey;
}
.searchBar_searchBarListItemInput__2okJ0{
    border: none;
    height: 30px;
    margin: 0 10px;
    font-size: 1rem;
}
.searchBar_rangePicker__EdRAC{
    min-width: 390px;
    height: 45px;
}
.searchBar_rangePicker__EdRAC input{
    font-size: 1rem !important;
    padding: 0 15px;
}
.searchBar_searchBarListBtn__BOJ9l{
    border: none;
    cursor: pointer;
    background-color: rgb(36,88,148);
    color: white;
    font-size: 1.2rem;
    font-weight: 600;
    border-radius: 3px;
}
.searchBar_searchBarDateBtn__3iTuU{
    background-color: rgb(36,88,148);
    color: white;
    font-weight: 700;
    font-size: 1.2rem;
    border: none;
    border-radius: 5px;
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
    cursor: pointer;
}
.searchBar_searchBarListItemPerson__1-Fuj{
    background-color: white;
    box-shadow: #a6a6a6 0 0 5px;
    position: absolute;
    -webkit-transform: translateY(100px);
            transform: translateY(100px);
    width: 200px;
    height: 150px;
    display: grid;
    padding: 0 10px;
    cursor: auto;
    align-items: center;
}
.searchBar_searchBarListItemPersonItem__3cG7p{
    display: grid;
    /*gap: 10px;*/
    grid-auto-flow: column;
    align-items: center;
    justify-content: space-between;
}
.searchBar_searchBarListItemPersonItemOption__3gpvJ{
    width: 100px;
    display: grid;
    grid-auto-flow: column;
    grid-gap: 10px;
    gap: 10px;
    justify-content: center;
    align-items: center;
}
.searchBar_searchBarListItemPersonItemOption__3gpvJ button{
    cursor: pointer;
}
.searchBar_searchBarListItemPersonItemOption__3gpvJ span{
    width: 22px;
    text-align: center;
}
.searchBar_searchBarListItemPersonItemOption__3gpvJ button{
    width: 28px;
    border: rgb(36,88,148) solid 1px;
    background-color: white;
    padding: 3px;
}
.searchBar_ant-picker-suffix__Bm983{
    display: none;
}
.searchBar_searchBarListItemPersonBtn__1kAKb{
    display: grid;
    cursor: pointer;
    border: none;
    background-color: rgb(36,88,148);
    color: white;
    font-weight: 700;
    border-radius: 3px;
}
.searchBar_searchBarbtn__3QK7g{
    background-color: rgb(36,87,148);
    text-align: center;
    display: grid;
    align-content: center;
    cursor: pointer;
    border-radius: 3px;
}
.searchBar_searchLink__1kt-B{
    font-size: 1.2rem;
    font-weight: 700;
    width: 100%;
    color: white;
    height: 100%;
}
.feature_wrapper__1pWRF{
    margin-top: 20px;
    width: 100vw;
    display: grid;
    justify-content: center;
    /*background-color: #bfa;*/
}
.feature_container__13ZAW{
    display: grid;
    grid-gap: 10px;
    gap: 10px;
}
.feature_firstLine__3morZ, .feature_secondLine__umB-n{
    display: grid;
    grid-gap: 10px;
    gap: 10px;
    max-width: 1120px;
    grid-auto-flow: column;
    min-width: 850px;
}
.feature_firstLine__3morZ{
    grid-template-columns: repeat(2, 1fr);
}
.feature_secondLine__umB-n{
    grid-template-columns: repeat(3, 1fr);
}
.feature_container__13ZAW img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
    border-radius: 5px;
}
.feature_firstLineItem__1hhym, .feature_secondLineItem__3KMof{
    display: grid;
    position: relative;
}
.feature_container__13ZAW span{
    font-weight: 700;
    font-size: 1.8rem;
    text-shadow: grey 0 0 10px;
    color: white;
    position: absolute;
    top: 5px;
    left: 20px
}
.browseByType_wrapper__2RpAO{
    width: 100vw;
    display: grid;
    justify-content: center;
}
.browseByType_container__2SUso{
    max-width: 1120px;
}
.browseByType_title__B9Gx_{
    font-size: 1.8rem;
    font-weight: 700;
    margin-top: 30px;
    margin-bottom: 20px;
}
.browseByType_content__l9E8W{
    display: grid;
    grid-gap: 10px;
    gap: 10px;
    grid-auto-flow: column;
    justify-content: space-between;
    grid-template-columns: repeat(4,1fr);
}
.browseByType_browseItem__2sg_3{
    display: grid;
    min-width: 220px;
    cursor: pointer;
}
.browseByType_browseItem__2sg_3 img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
}
.browseByType_browseItemTitle__1H2AW{
    margin-top: 5px;
    margin-bottom: -3px;
    font-size: 1rem;
    font-weight: 700;
}
.browseByType_browseItemIntro__1HRAN{
    font-size: 0.8rem;
    font-weight: 500;
    color: #6b6b6b;
}
.subscribe_wrapper__1EF7a{
    width: 100vw;
    display: grid;
    justify-content: center;
    align-items: center;
    background-color: rgb(9,33,76);
    height: 250px;
    margin-top: 30px;
    text-align: center;
}
.subscribe_container__2lGo3{
    width: 40vw;
    display: grid;
}
.subscribe_title__3X5LB{
    color: white;
    font-size: 1.5rem;
}
.subscribe_intro__3mdpS{
    color: #cccccc;
    font-size: 1rem ;
}
.subscribe_sub__pyWos{
    display: grid;
    grid-gap: 20px;
    gap: 20px;
    grid-auto-flow: column;
    grid-template-columns: 5fr 2fr;
    height: 48px;
    margin-top: 15px;
}
.subscribe_sub__pyWos input, .subscribe_sub__pyWos button{
    border: none;
    border-radius: 10px;
}
.subscribe_sub__pyWos button{
    background-color: rgb(48,111,188);
    color: white;
    font-size: 1.2rem;
    cursor: pointer;
}
.subscribe_sub__pyWos input{
    font-size: 1.2rem;
    padding: 0 10px;
}
.link_wrapper__296C9{
    width: 100vw;
    display: grid;
    justify-content: center;
}
.link_container__FG-Dc{
    width: 100vw;
    max-width: 1120px;
    margin-top: 20px;
    display: grid;
    grid-gap: 100px;
    gap: 100px;
}
.link_links__2kaHz{
    display: grid;
    grid-auto-flow: column;
}
.link_links__2kaHz ul{
    display: grid;
    grid-gap: 4px;
    gap: 4px;
    align-content: start;
    margin-left: 20px;
}
.link_links__2kaHz a{
    cursor: pointer;
    color: rgb(48,111,188);
}
.link_copyright__WucFW{
    padding: 20px 0;
    font-size: 0.5rem;
}
.hotelBody_wrapper__c3QpI{
    width: 100vw;
    display: grid;
    justify-content: center;
    margin-top: 30px;
}
.hotelBody_container__hKoI9{
    width: 100vw;
    max-width: 1120px;
    display: grid;
    grid-gap: 30px;
    gap: 30px;
    grid-auto-flow: column;
    grid-template-columns: auto 1fr;
}
.hotelBody_searchBar__1sq49{
    width: 280px;
    background-color: rgb(244,190,65);
    border-radius: 5px;
}
.hotelBody_searchBarTitle__3qlHc{
    font-size: 1.2rem;
    font-weight: 500;
}
.hotelBody_searchList__mUu7N{
    display: grid;
    grid-gap: 20px;
    gap: 20px;
}
.hotelBody_searchForm__3YTyB{
    display: grid;
    grid-auto-flow: row;
    padding: 20px;
}
.hotelBody_searchForm__3YTyB label{
    font-size: 0.8rem;
}
.hotelBody_searchForm__3YTyB input{
    border: none;
    height: 27px;
    border-radius: 2px;
    padding: 0 5px;
}
.hotelBody_searchForm__3YTyB button{
    border: none;
    background-color: rgb(48,111,188);
    color: white;
    padding: 12px;
    margin-top: 30px;
    border-radius: 2px;
    cursor: pointer;
}
.hotelBody_optionsSpan__3LlIJ{
    margin-top: 20px;
    font-weight: 600;
}
.ant-picker{
    border: none;
    width: 240px;
    height: 27px;
    /*font-size: 0.5rem;*/
}
.ant-picker-input input{
    font-size: 0.5rem;
}
.searchItem_container__C7lKz{
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: auto 1fr;
    grid-gap: 30px;
    gap: 30px;
    padding: 15px;
    border: solid 1px gainsboro;
    border-radius: 5px;
}
.searchItem_info__1JokB{
    display: grid;
    align-content: space-between;
}
.searchItem_images__1zT4B{
    height: 200px;
    width: 200px;
}
.searchItem_images__1zT4B img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
}
.searchItem_main__KDe_V{
    display: grid;
    grid-auto-flow: column;
    justify-content: space-between;
}
.searchItem_title__33pPN a{
    font-weight: 700;
    font-size: 1.2rem;
    color: rgb(107,107,107);
}
.searchItem_rate__iDAA7 span{
    display: grid;
    font-weight: 600;
    font-size: 0.9rem;
    padding: 3px;
    color: white;
    background-color: rgb(19,52,123);
    border-radius: 3px;
}
.searchItem_intro__1vK5L{
    display: grid;
    justify-content: space-between;
    grid-auto-flow: column;
}
.searchItem_feature__1O1Cy{
    font-weight: 500;
    font-size: .9rem;
}
.searchItem_details__2TPzQ{
    font-size: .7rem;
    font-weight: 500;
}
.searchItem_priceAndAvail__34uSc{
    display: grid;
    justify-items: end;
    grid-gap: 5px;
    gap: 5px;
}
.searchItem_price__3Cjwi{
    font-weight: 600;
    font-size: 1.2rem;
}
.searchItem_avail__1HV4f a{
    font-weight: 700;
    font-size: 1rem;
    color: white;
    background-color:rgb(48,111,188);
    padding: 5px 10px;
    border-radius: 3px;
}
.hotelDetail_wrapper__R2WHR{
    width: 100vw;
    display: grid;
    justify-content: center;
}
.hotelDetail_container__1v3Zs{
    width: 100vw;
    max-width: 1120px;
}
.hotelDetail_title__1XBos{
    margin: 20px 0;
    display: grid;
    grid-auto-flow: column;
    justify-content: space-between;
}
.hotelDetail_name__1cdFP{
    font-weight: 700;
    font-size: 1.5rem;
}
.hotelDetail_photos__1qLVJ{
    display: grid;
    grid-gap: 10px;
    gap: 10px;
    grid-template-columns: repeat(3, 1fr);
}
.hotelDetail_photos__1qLVJ img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}
.hotelDetail_title__1XBos a{
    border: none;
    background-color: rgb(48,111,188);
    color: white;
    font-weight: 700;
    font-size: 1rem;
    padding: 3px 8px;
    border-radius: 5px;
    display: grid;
    align-items: center;
}
.hotelDetail_info__16h8f{
    font-size: 0.8rem;
    margin: 20px 0;
}
.login_container__iHop3{
    background-color: #6b6b6b;
    height: 100vh;
    width: 100vw;
}
.login_title__18dAl{
    height: 100px;
    background-color: rgb(48,111,188);
    color: white;
    text-align: center;
    font-weight: 700;
    font-size: 2rem;
    line-height: 100px;
}
.login_body__12iWF{
    display: grid;
    justify-content: center;
    align-content: space-around;
    height: 50vh;
    background-color: rgb(255, 196, 0);
}
.register_container__1KkaN{
    background-color: #6b6b6b;
    height: 100vh;
    width: 100vw;
}
.register_title__285f7{
    height: 100px;
    background-color: rgb(48,111,188);
    color: white;
    text-align: center;
    font-weight: 700;
    font-size: 2rem;
    line-height: 100px;
}
.register_body__3JSek{
    display: grid;
    justify-content: center;
    align-content: space-around;
    height: 50vh;
    background-color: rgb(255, 196, 0);
}
.listProperty_container__mTiGJ{
    background-color: #6b6b6b;
    height: 100vh;
    width: 100vw;
}
.listProperty_title__1wEhz{
    height: 100px;
    background-color: rgb(48,111,188);
    color: white;
    text-align: center;
    font-weight: 700;
    font-size: 2rem;
    line-height: 100px;
}
.listProperty_body__3Si3r{
    display: grid;
    justify-content: center;
    align-content: space-around;
    height: 50vh;
    background-color: rgb(255, 196, 0);
}
.listProperty_item__33ZMs{
    display: grid;
    grid-gap: 20px;
    gap: 20px;
    grid-auto-flow: column;
    justify-content: space-between;
}
.listProperty_item__33ZMs input, select{
    width: 180px;
    border: solid 1px grey;
}
#listProperty_description__33UtM{
    height: 80px;

}
