.wrapper{
    width: 100vw;
    display: grid;
    justify-content: center;
}
.container{
    width: 100vw;
    max-width: 1120px;
}
.title{
    margin: 20px 0;
    display: grid;
    grid-auto-flow: column;
    justify-content: space-between;
}
.name{
    font-weight: 700;
    font-size: 1.5rem;
}
.photos{
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(3, 1fr);
}
.photos img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}
.title a{
    border: none;
    background-color: rgb(48,111,188);
    color: white;
    font-weight: 700;
    font-size: 1rem;
    padding: 3px 8px;
    border-radius: 5px;
    display: grid;
    align-items: center;
}
.info{
    font-size: 0.8rem;
    margin: 20px 0;
}