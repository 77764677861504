.wrapper{
    width: 100vw;
    display: grid;
    justify-content: center;
}
.container{
    width: 100vw;
    max-width: 1120px;
    margin-top: 20px;
    display: grid;
    gap: 100px;
}
.links{
    display: grid;
    grid-auto-flow: column;
}
.links ul{
    display: grid;
    gap: 4px;
    align-content: start;
    margin-left: 20px;
}
.links a{
    cursor: pointer;
    color: rgb(48,111,188);
}
.copyright{
    padding: 20px 0;
    font-size: 0.5rem;
}